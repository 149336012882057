import { combineReducers } from 'redux';
import modulAuth from './auth/Auth_lib';
import modulSms from './sms/Sms_lib';
import modulEmail from './email/Email_lib';
import modulUser from './user/User_lib';
import modulRole from './role/Role_lib';
import modulProviderSms from './sms/Provider_lib';
import modulTypeofnotification from './typeoff/Typeofnotification_lib';
import typeofPayment from './typeoff/Typeoffpayment_lib';
import modulCountry from './country/Country_lib';
import modulPaymentRequest from './payment-request/PaymentRequest_lib';
import modulPaymentGateway from './payment-gateway/PaymentGateway_lib';
import modulApps from './apps/Apps_lib';
import modulAppModules from './apps/AppModules_lib';
import modulAppMenus from './apps/AppMenus_lib';
import modulAppRoutes from './apps/AppRoutes_lib';
import modulUserPermission from './user-permission/UserPermission_lib';
import modulRolePermission from './role-permission/RolePermission_lib';
import modulCan from './can/Can_lib';
import modulRouters from './routers/Routers_lib';
import modulCountryProfile from './setting/Manage-country-profile_lib';
import modulSettingOrderTag from './setting/OrderTag_lib';
import modulSettingOrderStatus from './setting/OrderStatus_lib';
import modulSettingMerchantTag from './setting/MerchantTag_lib';
import modulSettingMerchantTagGroup from './setting/MerchantTagGroup_lib';
import modulSettingMerchantCategory from './setting/MerchantCategory_lib';
import modulSettingGiftanoCardStatus from './setting/GiftanoCardStatus_lib';
import modulSettingGiftStatus from './setting/GiftStatus_lib';
import modulArranggementExpiredMerchantCode from './setting/Arrangementexpiredcode_lib';
import modulArranggementMerchantCodePayment from './setting/ArrangmentMerchantCodePayment_lib';
import modulSettingTypeofMedia from './typeof/Media_lib';
import modulSettingTypeofPayment from './typeof/Payment_lib';
import modulSettingTypeofGeneralSetting from './typeof/GeneralSetting_lib';
import modulVoucherTag from './voucher/Vouchertag_lib';
import modulVoucherStatus from './voucher/Voucherstatus_lib';
import modulDeliveryStatus from './delivery/DeliveryStatus_lib';
import modulDeliveryActivity from './delivery/DeliveryActifity_lib';
import modulPackaging from './packaging/Packaging_lib';
import modulProductCategory from './product/Category_lib';
import modulProductTagGroup from './product/TagGroup_lib';
import modulProductTag from './product/Tag_lib';
import modulShoppingCartStatus from './shoppingcart/Shoppingcartstatus_lib';
import modulGeneralSetting from './setting/GeneralSetting_lib';
import modulExpeditionCompany from './setting/Expeditioncompany_lib';
import modulFaq from './strapi/faq/Faq_lib';
import modulFaqCategories from './strapi/faq/FaqCategories_lib';
import modulStrapiCountries from './strapi/country/StrapiCountry_lib';
import modulJobPostings from './strapi/job-postings/JobPostings_lib';

// language
import modulLanguageDictionary from './language/Dictionary_lib';
import modulLanguageByLanguageCode from './language/Dictionariesbylanguagecode_lib';
import modulSupportingLanguage from './language/Supportinglanguage_lib';
import modulLanguageTranslate from './language/Translate_lib';
import modulLanguageCode from './language/LanguageCode_lib';

// memberships
import modulMember from './memberships/Member_lib';
import modulTypeofAddress from './memberships/TypeofAddress_lib';
import modulTypeofDate from './memberships/TypeofDate_lib';
import modulTypeofIdentity from './memberships/TypeofIdentity_lib';
import modulTypeofPhoneNumber from './memberships/TypeofPhoneNumber_lib';
import modulTypeofRelationship from './memberships/TypeofRelationship_lib';
import modulTypeofSocialMedia from './memberships/TypeofSocialMedia_lib';
import modulContact from './memberships/Contact_lib';
import modulContactAddress from './memberships/ContactAddress_lib';
import modulContactEmail from './memberships/ContactEmail_lib';
import modulContactNumber from './memberships/ContactNumber_lib';
import modulContactDate from './memberships/Date_lib';

// company
import modulCompany from './company/Company_lib';
import modulCompanyMarket from './company/Market_lib';
import modulCompanyContact from './company/Contact_lib';
import modulCompanyMarketDelivery from './company/MarketDelivery_lib';
import modulTypeParnershipContact from './company/Typepatnertcontact_lib';
import modulCompanyGeneralConf from './company/Generalconf_lib';
import modulCompanyWhiteLabel from './company/Whitelabel_lib';
import modulCompanyNotification from './company/Notifications_lib';
import modulCompanyRecepient from './company/Recepient_lib';
import modulCompanyTypeofCompany from './company/Typeofcompany_lib';
import modulCompanyTypeofAccount from './company/Typeofaccountcompany_lib';
// merchant
import modulMerchantGroups from './merchant/Merchantgroups_lib';
import modulMerchant from './merchant/Merchant_lib';
import modulMerchantAddress from './merchant/Address_lib';
import modulMerchantTypeofContact from './merchant/Typeofcontact_lib';
import modulMerchantContactLabel from './merchant/Contactlabel_lib';
import modulMerchantContact from './merchant/Contact_lib';
import modulMerchantContactEmail from './merchant/ContactEmail_lib';
import modulMerchantContactPhoneNumber from './merchant/ContactPhoneNumber_lib';

// tagging
import modulTaggingTypeofTag from './tagging/Typeoftag_lib';
import modulTaggingTagGroup from './tagging/TagGroup_lib';
import modulTaggingTagCountry from './tagging/TagCountry_lib';
import modulMerchantMarketplace from './merchant/Marketplace_lib';
import modulMerchantBranch from './merchant/Branch_lib';

import modulTagggingTag from './tagging/Tag_lib';
import modulInventory from './inventory/Vendor_lib';
// modals
import modals from './modal/Modal_lib';

// contact
import modulContactTypeContacGroup from './contact/Typeofcontactgroup_lib';
import modulContactLabel from './contact/ContactLabel_lib';
import modulContactAll from './contact/Contact_lib';
import modulContactAddressAll from './contact/Contactaddress_lib';
import modulContactPhoneNumberAll from './contact/ContactPhoneNumber_lib';
import modulContactEmailAll from './contact/Contactemail_lib';
import modulContactDateAll from './contact/ContactDate_lib';
// contact

//fraud
import modulTypeofInformation from './fraud/Typeofinformation_lib';
import modulCategoriInformation from './fraud/Categoryinformation_lib';
import modulFraudInformation from './fraud/Fraudinformation_lib';
// models
import modulWhitelabelTheme from './whitelabel/WhitelabelThemes_lib';
// traffic source
import modulTrafficSource from './traffic-source/TrafficSource_lib';

//  saleschannel
import modulSalesChannel from './saleschannel/SalesChannel_lib';

// menu
import modulMenus from './apps/Menu_lib';
import modulPermissionMenus from '../models/permission-menus/PermissionMenus_lib';

// product
import modulProductTypeofProduct from './product/Typeofproduct_lib';
import modulProductCategoryMerchant from './product/ProductCategory_lib';
import modulProduct from './product/Product_lib';

// form builder
import modulFormGenerator from './formgenerator/FormGenerator_lib';
import modulFormSet from './formgenerator/FormSet_lib';

// logout
import { modulLogout } from './logout';
import { InternalUserReducer, modulUserProfile } from './user';

import { modulApp } from './app';
import { manageRole, manageApp, manageMenus, managePermissionMenu } from './appusermanagement';

import { modulProfileAccess } from './profileAccess';

import AppUsersReducer from './apps/AppUsersReducer';

import { modulReportAccess } from './modulAccess';
import { modulWebhookProvider } from './webhook';
import { modulGeneralSettingAdvance, modulGeneralSettingOptionList } from './GeneralSetting';
import { modulDefaultSetting } from './defaultSetting';
import { modulWhitelabelSchema } from './whitelabel';
import GrabOrderHistorySlice from './merchant-code-api/grab/order-history/GrabOrderHistorySlice';
import SupplierGrabSlice from './merchant-code-api/grab/suppliers/SupplierGrabSlice';
import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import {
  modulMandrillDomains,
  modulMandrillList,
  modulMandrillTimeSeries,
  modulMandrillSubAccount,
} from './mandrill-monitoring';
import { modulGoogleSchedular } from './google';
import GrabCancelHistorySlice from './merchant-code-api/grab/cancel-history/GrabCancelHistorySlice';
import { modulAppleDomain } from './domain';
import { modulQrGift } from './qr-gift';

const reducers = {
  modulAuth,
  modals,
  modulSms,
  modulEmail,
  modulUser,
  modulRole,
  modulProviderSms,
  modulCountry,
  modulTypeofnotification,
  typeofPayment,
  modulPaymentRequest,
  modulPaymentGateway,
  modulApps,
  modulAppModules,
  modulAppMenus,
  modulAppRoutes,
  modulUserPermission,
  modulRolePermission,
  modulCan,
  modulRouters,
  modulCountryProfile,
  modulSettingOrderTag,
  modulSettingOrderStatus,
  modulSettingMerchantTag,
  modulSettingMerchantTagGroup,
  modulSettingMerchantCategory,
  modulSettingGiftanoCardStatus,
  modulSettingGiftStatus,
  modulArranggementExpiredMerchantCode,
  modulArranggementMerchantCodePayment,
  modulSettingTypeofMedia,
  modulSettingTypeofPayment,
  modulSettingTypeofGeneralSetting,
  modulVoucherTag,
  modulVoucherStatus,
  modulDeliveryStatus,
  modulDeliveryActivity,
  modulPackaging,
  modulProductCategory,
  modulProductTagGroup,
  modulProductTag,
  modulShoppingCartStatus,
  modulGeneralSetting,
  modulExpeditionCompany,
  modulLanguageDictionary,
  modulLanguageByLanguageCode,
  modulSupportingLanguage,
  modulFaq,
  modulFaqCategories,
  modulStrapiCountries,
  modulJobPostings,
  modulLanguageTranslate,
  modulLanguageCode,
  modulCompany,
  modulCompanyMarket,
  modulCompanyContact,
  modulCompanyMarketDelivery,
  modulTypeParnershipContact,
  modulCompanyGeneralConf,
  modulCompanyWhiteLabel,
  modulCompanyNotification,
  modulCompanyRecepient,
  modulCompanyTypeofCompany,
  modulCompanyTypeofAccount,
  modulMember,
  modulTypeofAddress,
  modulTypeofDate,
  modulTypeofIdentity,
  modulTypeofPhoneNumber,
  modulTypeofRelationship,
  modulTypeofSocialMedia,
  modulContact,
  modulContactAddress,
  modulContactEmail,
  modulContactNumber,
  modulContactDate,
  modulMerchantGroups,
  modulMerchant,
  modulMerchantAddress,
  modulMerchantTypeofContact,
  modulMerchantContactLabel,
  modulMerchantContact,
  modulMerchantContactEmail,
  modulMerchantContactPhoneNumber,
  modulTaggingTypeofTag,
  modulTaggingTagGroup,
  modulTaggingTagCountry,
  modulTagggingTag,
  modulMerchantMarketplace,
  modulMerchantBranch,
  modulTypeofInformation,
  modulCategoriInformation,
  modulFraudInformation,
  modulContactLabel,
  modulContactAll,
  modulContactAddressAll,
  modulContactPhoneNumberAll,
  modulContactEmailAll,
  modulContactDateAll,
  modulWhitelabelTheme,
  modulSalesChannel,
  modulTrafficSource,
  modulContactTypeContacGroup,
  modulInventory,
  modulMenus,
  modulProductTypeofProduct,
  modulProductCategoryMerchant,
  modulProduct,
  modulFormGenerator,
  modulFormSet,
  modulLogout,
  modulUserProfile,
  modulApp,
  manageRole,
  manageApp,
  manageMenus,
  managePermissionMenu,
  modulPermissionMenus,
  appUsers: AppUsersReducer,
  modulProfileAccess: modulProfileAccess,
  internalUser: InternalUserReducer,
  modulReportAccess: modulReportAccess,
  modulWebhookProvider: modulWebhookProvider,
  modulGeneralSettingAdvance: modulGeneralSettingAdvance,
  modulGeneralSettingOptionList: modulGeneralSettingOptionList,
  modulDefaultSetting: modulDefaultSetting,
  modulWhitelabelSchema: modulWhitelabelSchema,
  grabOrderHistory: GrabOrderHistorySlice,
  grabCancelHistory: GrabCancelHistorySlice,
  supplierGrab: SupplierGrabSlice,
  modulMandrillList: modulMandrillList,
  modulMandrillDomains: modulMandrillDomains,
  modulMandrillTimeSeries: modulMandrillTimeSeries,
  modulMandrillSubAccount: modulMandrillSubAccount,
  modulGoogleSchedular: modulGoogleSchedular,
  modulAppleDomain: modulAppleDomain,
  modulQrGift: modulQrGift,
};

export const store = configureStore({
  reducer: reducers,
  middleware: [thunk],
  devTools: process.env.NODE_ENV !== 'production',
});
