import { CONNECTION } from '../../config/Connection';
import { CONFIG_ } from '../../config/Config';
import qs from 'qs';
import { notifDanger, notifSuccess } from '../../component/notif/Notification';
import { CLOSE_MODAL_VIEW_QRCODE } from '../action-reducer-type';

var get = '';
var post = '';
var isLive = false;
const PATH = process.env.REACT_APP_PATH_QR_GIFT;

export const __getDataQrGift = (params) => {
  return (dispatch) => {
    let config = CONFIG_({ url: `get-all?${qs.stringify(params)}`, method: 'GET', path: PATH });
    dispatch({ type: 'LOADING_QRGIFT_QR-GIFT', filter: params });
    CONNECTION(config)
      .then((response) => {
        const { code, data, meta } = response;
        if (code === 200) {
          return dispatch({
            type: 'SUCCESS_QRGIFT_QR-GIFT',
            message: 'Success get data',
            data: data,
            pagination: meta.pagination,
          });
        }
        return dispatch({ type: 'ERROR_QRGIFT_QR-GIFT', message: 'failed' });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({ type: 'ERROR_QRGIFT_QR-GIFT', message: 'Network error..' });
      });
  };
};

export const __restoretDataQrGift = (params, filter) => {
  return (dispatch) => {
    let config = CONFIG_({
      url: `undelete/${params.id}`,
      method: 'POST',
      path: PATH,
    });
    dispatch({ type: 'LOADING_QRGIFT_QR-GIFT' });
    CONNECTION(config)
      .then((response) => {
        const { code } = response;
        if (code === 200) {
          notifSuccess('The gift has been successfully restored');
          dispatch({ type: CLOSE_MODAL_VIEW_QRCODE });
          return dispatch(__getDataQrGift(filter));
        }

        notifDanger('Action failed: The case is invalid or does not meet the required conditions.');
        return dispatch(__getDataQrGift(filter));
      })
      .catch((response) => {
        console.log(response);
        return dispatch({ type: 'ERROR_QRGIFT_QR-GIFT', message: 'Network error..' });
      });
  };
};
