export const SEND_DATA_PAYMENT_REQUEST = 'SEND_DATA_PAYMENT_REQUEST';
export const LOADING_DATA_PAYMENT_REQUEST = 'LOADING_DATA_PAYMENT_REQUEST';
export const SUCCESS_PAYMENT_REQUEST = 'SUCCESS_PAYMENT_REQUEST';
export const ERROR_PAYMENT_REQUEST = 'ERROR_PAYMENT_REQUEST';
export const SHOW_MODAL_PAYMENT_REQUEST = 'SHOW_MODAL_PAYMENT_REQUEST';
export const CLOSE_MODAL_PAYMENT_REQUEST = 'CLOSE_MODAL_PAYMENT_REQUEST';

export const SET_USER_PERMISSION = 'SET_USER_PERMISSION';
export const SHOW_MODAL_USER_PERMISSION = 'SHOW_MODAL_USER_PERMISSION';
export const CLOSE_MODAL_USER_PERMISSION = 'CLOSE_MODAL_USER_PERMISSION';

export const SET_ROLE_PERMISSION = 'SET_ROLE_PERMISSION';
export const SHOW_MODAL_ROLE_PERMISSION = 'SHOW_MODAL_ROLE_PERMISSION';
export const CLOSE_MODAL_ROLE_PERMISSION = 'CLOSE_MODAL_ROLE_PERMISSION';

export const SET_MODULES = 'SET_MODULES';
export const SHOW_MODAL_MODULES = 'SHOW_MODAL_MODULE';
export const CLOSE_MODAL_MODULE = 'CLOSE_MODAL_MODULE';

export const SET_DETAIL_ROLE = 'SET_ROLE_DETAIL';
export const SHOW_MODAL_ROLE = 'SHOW_MODAL_ROLE';
export const CLOSE_MODAL_ROLE = 'CLOSE_MODAL_ROLE';

export const MODAL_SUCCESS = 'MODAL_SUCCESS';

export const SET_CAN = 'SET_CAN';

export const SET_ROUTERS = 'SET_ROUTERS';

export const SET_FAQS = 'SET_FAQS';
export const ERROR_FAQS = 'ERROR_FAQS';
export const LOADING_DATA_FAQS = 'LOADING_DATA_FAQS';
export const LOADING_SEND_FAQS = 'LOADING_SEND_FAQS';
export const SHOW_MODAL_FAQS = 'SHOW_MODAL_FAQS';
export const CLOSE_MODAL_FAQS = 'CLOSE_MODAL_FAQS';

export const SET_FAQ_CATEGORIES = 'SET_FAQ_CATEGORIES';
export const ERROR_FAQ_CATEGORIES = 'ERROR_FAQ_CATEGORIES';
export const LOADING_DATA_FAQ_CATEGORIES = 'LOADING_DATA_FAQ_CATEGORIES';
export const LOADING_SEND_FAQ_CATEGORIES = 'LOADING_SEND_FAQ_CATEGORIES';
export const SHOW_MODAL_FAQ_CATEGORIES = 'SHOW_MODAL_FAQ_CATEGORIES';
export const CLOSE_MODAL_FAQ_CATEGORIES = 'CLOSE_MODAL_FAQ_CATEGORIES';

export const SET_STRAPI_COUNTRY = 'SET_STRAPI_COUNTRY';
export const ERROR_STRAPI_COUNTRY = 'ERROR_STRAPI_CONTRY';
export const LOADING_DATA_STRAPI_COUNTRY = 'LOADING_DATA_STRAPI_COUNTRY';
export const LOADING_SEND_STRAPI_COUNTRY = 'LOADING_SEND_STRAPI_COUNTRY';
export const SHOW_MODAL_STRAPI_COUNTRY = 'SHOW_MODAL_STRAPI_COUNTRY';
export const CLOSE_MODAL_STRAPI_COUNTRY = 'CLOSE_MODAL_STRAPI_COUNTRY';

export const SET_JOB_POSTINGS = 'SET_JOB_POSTINGS';
export const ERROR_JOB_POSTINGS = 'ERROR_JOB_POSTINGS';
export const LOADING_DATA_JOB_POSTINGS = 'LOADING_DATA_JOB_POSTINGS';
export const LOADING_SEND_JOB_POSTINGS = 'LOADING_SEND_JOB_POSTINGS';
export const SHOW_MODAL_JOB_POSTINGS = 'SHOW_MODAL_JOB_POSTINGS';
export const CLOSE_MODAL_JOB_POSTINGS = 'CLOSE_MODAL_JOB_POSTINGS';

export const LOADING_DATA_USER_PROFILE = 'LOADING_DATA_USER_PROFILE';
export const LOADING_SEND_USER_PROFILE = 'LOADING_SEND_USER_PROFILE';

export const SHOW_MODAL_VIEW_QRCODE = 'SHOW_MODAL_VIEW_QRCODE';
export const CLOSE_MODAL_VIEW_QRCODE = 'CLOSE_MODAL_VIEW_QRCODE';
