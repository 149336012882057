import { CLOSE_MODAL_VIEW_QRCODE, SHOW_MODAL_VIEW_QRCODE } from '../action-reducer-type';

const defaultState = {
  loadingPage: true,
  loadingSend: true,
  restData: [],
  code: 500,
  message: '',
  status: 'default',
  filter: {
    filters: {
      is_deleted: true,
    },
  },
  modalIsOpen: false,
  dataModal: {},
  pagination: {
    limit: 10,
    total: 0,
    page: 1,
  },
};

let response = {};
const QrGiftLib = (state = defaultState, action = {}) => {
  switch (action.type) {
    case 'SUCCESS_QRGIFT_QR-GIFT':
      response = {
        ...state,
        restData: action.data ? action.data : '',
        code: action.code ? action.code : 500,
        pagination: action.pagination ? action.pagination : 500,
        loadingPage: false,
        loadingSend: false,
        status: 'success',
        message: action.message ? action.message : '',
      };

      break;
    case 'LOADING_QRGIFT_QR-GIFT':
      response = {
        ...state,
        loadingPage: true,
        loadingSend: true,
        status: 'loading',
        filter: action.filter,
      };
      break;

    case SHOW_MODAL_VIEW_QRCODE:
      response = {
        ...state,
        modalIsOpen: true,
        dataModal: action.dataModal,
      };
      break;
    case CLOSE_MODAL_VIEW_QRCODE:
      response = {
        ...state,
        modalIsOpen: false,
        dataModal: {},
      };
      break;
    case 'ERROR_QRGIFT_QR-GIFT':
      response = {
        ...state,
        status: 'success',
        message: action.message ? action.message : '',
        code: action.code ? action.code : 500,
      };
      break;
    default:
      return {
        ...state,
        loadingPage: false,
        loadingSend: false,
      };
  }

  return response;
};

export default QrGiftLib;
